import React from 'react'
import './works.css'
import port1 from '../../assets/Bliss-garden.jpg'
import port2 from '../../assets/Imperial.jpg'
import port3 from '../../assets/automatebiz.jpg'
import port4 from '../../assets/dr-abhinanda.jpg'
import port5 from '../../assets/jrrubber.jpg'
import port6 from '../../assets/telospest.jpg'

function Works() {
  return (
    <section id="works">
        <h2 className="workTitle">Portfolio</h2>
        <span className="workDesc">Explore a curated showcase of my projects, highlighting expertise, creativity, and client success stories.</span>
        <div className="workImgs">
            <img src={port1} alt="" className="workImg" />
            <img src={port2} alt="" className="workImg" />
            <img src={port3} alt="" className="workImg" />
            <img src={port4} alt="" className="workImg" />
            <img src={port6} alt="" className="workImg" />
            <img src={port5} alt="" className="workImg" />
        </div>
    </section>
  )
}

export default Works;