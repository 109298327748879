import React from 'react'
import './intro.css';
import bg from '../../assets/portfolio.bg.png'
import { Link } from 'react-scroll'
import hire from '../../assets/cart.png'

function Intro() {
  return (
    <section id="intro">
        <div className="introContent">
        <span className="hello">Hello,</span>
        <span className="introText">I'm <span className="introName">Arjun</span><br />Website Developer</span>
        <p className="introPhara">Website Developer crafting innovative, user-friendly sites with cutting-edge design and functionality</p>
        <Link><button className="btn"><img src={hire} alt="hire" className='btnImg' /><span>Hire Me</span></button></Link>
        </div>
        <img src={bg} alt="" className="bg" />
        <div className="overlay-fade"></div>
    </section>
  )
}

export default Intro