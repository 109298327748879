import React from 'react'
import './footer.css'

function Footer() {
  return (
    <footer className='footer'>
        Copyright &#169; 2023 Arjun. All Right Recived.
    </footer>
  )
}

export default Footer;