import React, { useRef } from 'react'
import './contact.css'
import wordpress from '../../assets/WordPress.png'
import figma from '../../assets/Figma.png'
import shopify from '../../assets/Shopify.png'
import react from '../../assets/React.png'
import linkdin from '../../assets/linkedin.png'
import fb from '../../assets/facebook.png'
import insta from '../../assets/instagram.png'
import emailjs from '@emailjs/browser';

const Contact=()=> {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_6f1i3ad', 'template_s1eunfa', form.current, '6jLtueE0c5e0q1DPx')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <section id="contactPage">
        <div id="clinents">
        <h2 className="title">Technology Know</h2>
        <span className="disc">Discover the cutting-edge technologies I leverage to craft efficient, modern, and innovative digital solutions.</span>
        </div>
        <div className="techImgs">
          <img src={wordpress} alt="" className="tech" />
          <img src={figma} alt="" className="tech" />
          <img src={shopify} alt="" className="tech" />
          <img src={react} alt="" className="tech" />
        </div>
          <div id="contact">
           <h2 className="title">Connect Me</h2>
           <span className="disc">Reach out directly for collaborations, inquiries, or consultations. Let's transform your vision into reality.</span>
         <form action="" className="contactForm" ref={form} onSubmit={sendEmail}>
             <input type="text" className="name" placeholder='Name' name='your_name'/>
             <input type="text" className="email" placeholder='Email' name='your_email' />
             <input type="text" className="number" placeholder='Number' name='your_number' />
             <textarea name="message"rows="5" className="message" placeholder='Message' ></textarea>
             <button type='submit' value='Send' className="submit">Submit</button>
            <div className="links">
              <img src={insta} alt="" className="link" />
              <img src={fb} alt="" className="link" />
              <img src={linkdin} alt="" className="link" />
            </div>
          </form>
        </div>
    </section>
  )
}

export default Contact;