import React from 'react'
import './skills.css'
import Ux from '../../assets/ux.png'
import webDevelopment from '../../assets/web development.png'
import Ui from '../../assets/ui-design.png'

function Skills() {
  return (
   <section id="skills">
     <span className="skillTitle">What I do</span>
     <span className="skillDescriotion">Services Tailored to Elevate Your Digital Presence</span>
     <div className="skillBars">
        <div className="skillBar">
            <img src={webDevelopment} alt="" className="skillBarImg" />
            <div className="skillBartext">
                <h2>Web Development</h2>
                <p>Crafting robust and dynamic websites tailored to your specific business needs.</p>
            </div>
        </div>
        <div className="skillBar">
            <img src={Ui} alt="" className="skillBarImg" />
            <div className="skillBartext">
                <h2>UI/UX Design</h2>
                <p>Designing intuitive user interfaces with an emphasis on enhancing user experience.</p>
            </div>
        </div>
        <div className="skillBar">
            <img src={Ux} alt="" className="skillBarImg" />
            <div className="skillBartext">
                <h2>Web Design</h2>
                <p>Creating visually compelling website layouts that resonate with your brand's identity.</p>
            </div>
        </div>
     </div>
   </section>
  )
}

export default Skills