import React, { useState } from 'react'
import './navbar.css'
import logo from '../../assets/arjun-logo.png'
import { Link } from 'react-scroll'
import contactImg from '../../assets/contact-us.png'
import Menu from '../../assets/open-menu.png'

function Navbar() {
  const [showMenu, setShowMenu]= useState(false);
  return (
    <nav className="navbar">
        <img src={logo} alt="logo" className='logo' />
        <div className="desktopMenu">
        <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-100} duration={500} className="desktopMenuListItems">Home</Link>
        <Link activeClass='active' to='skills' spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItems">About</Link>
        <Link activeClass='active' to='works' spy={true} smooth={true} offset={-100} duration={500} className="desktopMenuListItems">Portfolio</Link>
        <Link activeClass='active' to='tech' spy={true} smooth={true} offset={-250} duration={500} className="desktopMenuListItems">Tech</Link>
        </div>
        <button className="desktopMenuBtn" onClick={()=>{
          document.getElementById('contact').scrollIntoView({behavior:'smooth'});
        }}>
            <img src={contactImg} alt="" className="desktopMenuImg" />Contact Me</button>

            <img src={Menu} alt="menu" className='mobMenu' onClick={()=>setShowMenu(!showMenu)}/>
        <div className="navMenu" style={{display: showMenu? 'flex':'none'}}>
        <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-100} duration={500} className="ListItems" onClick={()=>setShowMenu(false)}>Home</Link>
        <Link activeClass='active' to='skills' spy={true} smooth={true} offset={-50} duration={500} className="ListItems" onClick={()=>setShowMenu(false)}>About</Link>
        <Link activeClass='active' to='works' spy={true} smooth={true} offset={-100} duration={500} className="ListItems" onClick={()=>setShowMenu(false)}>Portfolio</Link>
        <Link activeClass='active' to='tech' spy={true} smooth={true} offset={-260} duration={500} className="ListItems" onClick={()=>setShowMenu(false)}>Tech</Link>
        <Link activeClass='active' to='contact' spy={true} smooth={true} offset={-80} duration={500} className="ListItems" onClick={()=>setShowMenu(false)}>Contact</Link>
        </div>
    </nav>
  )
}

export default Navbar